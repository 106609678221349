
import {
  ProductTypes,
  SystemProduct,
} from "@/store/modules/product/product.types";
import { Pagination } from "@/types/types";
import { Component, Vue } from "vue-property-decorator";
import { DataTableHeader } from "vuetify";
import { namespace } from "vuex-class";
import { commaDate, currency, pad, slash, dash, uuid } from "@/filters/utils";

const productX = namespace("Product");

@Component({
  components: {
    IDatePicker: () => import("@/components/utils/IDatePicker.vue"),
    AddProduct: () => import("@/components/product/AddProduct.vue"),
    EditProduct: () => import("@/components/product/EditProduct.vue"),
    DeleteProduct: () => import("@/components/product/DeleteProduct.vue"),
  },
  filters: {
    currency,
    slash,
    commaDate,
    pad,
    dash,
    uuid,
  },
})
export default class ProductsView extends Vue {
  @productX.Mutation(ProductTypes.SET_ADD_PRODUCT_DIALOG)
  public setAddProduct!: (addProduct: boolean) => void;

  @productX.Mutation(ProductTypes.SET_UPDATE_PRODUCT_DIALOG)
  public setUpdateProduct!: (updateProduct: boolean) => void;

  @productX.Mutation(ProductTypes.SET_DELETE_PRODUCT_DIALOG)
  public setDeleteProduct!: (deleteProduct: boolean) => void;

  @productX.Mutation(ProductTypes.SET_TICKET_PRODUCT_DATA)
  public setSystemProductData!: (ticketProduct: SystemProduct) => void;

  @productX.Action(ProductTypes.LOAD_PRODUCTS)
  public getTicketProducts!: (pagination: Pagination) => void;

  @productX.State(ProductTypes.PRODUCTS)
  public ticketProducts!: SystemProduct[];

  @productX.State(ProductTypes.LOADING_PRODUCTS_STATE)
  public loadingProducts!: boolean;

  @productX.State("addProductRef")
  public addProductRef!: number;

  openAddProductDialog(): void {
    this.setAddProduct(true);
  }

  public search = "";

  public headers: DataTableHeader[] = [
    {
      text: "#ID",
      sortable: false,
      value: "id",
      cellClass: "primary--text text--darken-2 font-weight-medium",
    },
    { text: "NAME", value: "name", cellClass: "grey--text text--darken-2" },
    {
      text: "DESCRIPTION",
      value: "description",
      sortable: false,
      cellClass: "grey--text text--darken-2",
    },
    {
      text: "STATUS",
      value: "active",
      sortable: false,
      cellClass: "grey--text text--darken-2",
    },
    {
      text: "CREATED",
      value: "createdAt",
      sortable: false,
      cellClass: "grey--text text--darken-2",
    },
    {
      text: "ACTIONS",
      value: "actions",
      sortable: false,
      cellClass: "grey--text text--darken-2",
    },
  ];

  deleteProduct(item: SystemProduct): void {
    this.setDeleteProduct(true);
    this.setSystemProductData(item);
  }

  editProduct(item: SystemProduct): void {
    this.setUpdateProduct(true);
    this.setSystemProductData(item);
  }

  mounted(): void {
    this.getTicketProducts({ page: 1, limit: 10 });
  }
}
